import React from "react";
import { graphql, Link } from "gatsby";

import SEO from "../../../components/seo";
import PortableText from "../../../components/portableText";
import CycleImage from "../../../components/cycle-image";

import explicit from "../../../images/hate-symbol-examples/explicit.webp";
import contextual from "../../../images/hate-symbol-examples/contextual.webp";
import characters from "../../../images/hate-symbol-examples/characters.webp";
import contemporary from "../../../images/hate-symbol-examples/contemporary.webp";
import historical from "../../../images/hate-symbol-examples/historical.webp";
import allSymbols from "../../../images/hate-symbol-examples/all.webp";
import SearchUI from "../../../components/search/SearchUI";

export const query = graphql`
  query SymbolsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawSymbolsPageContentFrench(resolveReferences: { maxDepth: 5 })
      _rawSymbolsPageContent(resolveReferences: { maxDepth: 5 })
      publicSymbolsExplicitDescription_french
      publicSymbolsExplicitDescription
      publicSymbolsContextualDescription_french
      publicSymbolsContextualDescription
      publicSymbolsCharactersDescription_french
      publicSymbolsCharactersDescription
      publicSymbolsContemporaryDescription_french
      publicSymbolsContemporaryDescription
      publicSymbolsHistoricalDescription_french
      publicSymbolsHistoricalDescription
      _rawSymbolsPageConclusionFrench(resolveReferences: { maxDepth: 5 })
      _rawSymbolsPageConclusion(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const SymbolsPage = ({ data = {} }) => {
  const introduction =
    data.site &&
    (data.site._rawSymbolsPageContentFrench ||
      data.site._rawSymbolsPageContent);
  const {
    publicSymbolsExplicitDescription_french,
    publicSymbolsExplicitDescription,
    publicSymbolsContextualDescription_french,
    publicSymbolsContextualDescription,
    publicSymbolsCharactersDescription_french,
    publicSymbolsCharactersDescription,
    publicSymbolsContemporaryDescription_french,
    publicSymbolsContemporaryDescription,
    publicSymbolsHistoricalDescription_french,
    publicSymbolsHistoricalDescription,
  } = data.site ?? {};
  const conclusion =
    data.site &&
    (data.site._rawSymbolsPageConclusionFrench ||
      data.site._rawSymbolsPageConclusion);
  return (
    <>
      <SEO title="Guide à la haine en ligne" />
      {introduction && (
        <div className="prose dark:prose-invert prose-lg md:pt-12 md:pb-7 public-symbols-page-content">
          <PortableText blocks={introduction} />
        </div>
      )}
      <div className="pb-7">
        <SearchUI isFrench />
      </div>
      <ul className="link-grid condensed grid gap-x-8 gap-y-12 w-full mb-16 mt-12 md:mt-0">
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./explicites" className="h-full flex flex-col">
            <CycleImage
              src={explicit}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Haine explicite
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              {publicSymbolsExplicitDescription_french ||
                publicSymbolsExplicitDescription}
            </div>
          </Link>
        </li>
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./contextuels" className="h-full flex flex-col">
            <CycleImage
              src={contextual}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Haine contextuelle
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              {publicSymbolsContextualDescription_french ||
                publicSymbolsContextualDescription}
            </div>
          </Link>
        </li>
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./personnages" className="h-full flex flex-col">
            <CycleImage
              src={characters}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Mèmes avec usage haineux
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              {publicSymbolsCharactersDescription_french ||
                publicSymbolsCharactersDescription}
            </div>
          </Link>
        </li>
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./contemporains" className="h-full flex flex-col">
            <CycleImage
              src={contemporary}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Groupes haineux canadiens contemporains
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              {publicSymbolsContemporaryDescription_french ||
                publicSymbolsContemporaryDescription}
            </div>
          </Link>
        </li>
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./historiques" className="h-full flex flex-col">
            <CycleImage
              src={historical}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Groupes haineux canadiens historiques
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              {publicSymbolsHistoricalDescription_french ||
                publicSymbolsHistoricalDescription}
            </div>
          </Link>
        </li>
        <li className="mx-auto md:mx-0 max-w-[380px] md:max-w-none">
          <Link to="./tout" className="h-full flex flex-col">
            <CycleImage
              src={allSymbols}
              className="w-full aspect-square object-cover bg-red-900 crisp"
            />
            <div className="pt-3 pb-2 font-sans font-bold text-4xl uppercase text-gray-900 dark:text-gray-100">
              Liste complète
            </div>
            <div className="text-lg text-gray-700 dark:text-gray-300">
              Tous les symboles, termes et thèmes des autres catégories.
            </div>
          </Link>
        </li>
      </ul>
      {conclusion && (
        <div className="prose dark:prose-invert prose-lg pt-8 pb-16 md:pt-16 italic text-center md:text-left">
          <PortableText blocks={conclusion} />
        </div>
      )}
    </>
  );
};

export default SymbolsPage;
